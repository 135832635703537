/*!
* @Author: Beldjouhri Abdelghani
* @Date:   2016-07-21 09:59:28
* @Last Modified by:   Abdelghani
* @Last Modified time: 2016-11-15 10:25:59
*
* Main script for initialize plugins and other stuff !
*/

window.SCREEN = {
  xs_min : Modernizr.mq('(min-width: 480px)'),
  sm_min : Modernizr.mq('(min-width: 768px)'),
  md_min : Modernizr.mq('(min-width: 992px)'),
  lg_min : Modernizr.mq('(min-width: 1200px)'),
  xl_min : Modernizr.mq('(min-width: 1441px)'),
  xxl_min: Modernizr.mq('(min-width: 2000px)'),

  xs_max : Modernizr.mq('(max-width: 767px)'),
  sm_max : Modernizr.mq('(max-width: 991px)'),
  md_max : Modernizr.mq('(max-width: 1199px)'),
  lg_max : Modernizr.mq('(max-width: 1440px)'),
  xl_max : Modernizr.mq('(max-width: 1999px)'),
  // xll_max: Modernizr.mq('(max-width: 1439px)'),
}

jQuery.fn.appear = function() {
  $(this).css({
    'display': 'block',
    'opacity': 0,
  });

  $(this).animate(
    {opacity: '0.25'},
    400
  );
}

jQuery.fn.vanish = function() {
  var elem = $(this);

  elem.animate(
    {opacity: '0'},
    400,
    function() {elem.css('display', 'none')}
  );
}

jQuery(function($) {

  // Enable tooltip -----------------------------------------------------------

  $('[data-toggle="tooltip"]').tooltip({container: 'body'});

  // Search Bar show/hide Logic -----------------------------------------------

  (function() {

    var search_input = $('.search .search-input');
    var interval = null;

    $('.search').submit(function(e){

      if($('.navbar-text').hasClass('search-open')) {
        return true;
      }

      e.preventDefault();

      $('.navbar-text').addClass('search-open');

      search_input.focus();

      interval = setInterval(function() {

        if(search_input.val().length == 0) {
          search_input.blur();
        }

      }, 4000);

    })


    search_input.blur(function(){
      $('.navbar-text.search-open').removeClass('search-open');
      clearInterval(interval);
    })

  })()

  // Open all external links in new window ------------------------------------

  $('a:not(.no-link)').each(function() {
   var host = new RegExp('/' + window.location.host + '/');
   var uploads = new RegExp(/\/uploads\//);
   if(!host.test(this.href) || uploads.test(this.href) || $(this).hasClass('ex-link')) {
       $(this).click(function(event) {
          event.preventDefault();
          event.stopPropagation();
          window.open(this.href, '_blank');
       });
   }
  });

});
